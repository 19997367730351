import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConsentDialog } from './ConsentDialog';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<ConsentDialog/>}/>
          <Route path="/:memberId" element={<ConsentDialog/>}/>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
