import {
  ApplicationOptionsContext,
  ErrorHandlingContext,
  ErrorMessageMissingMemberId,
  handleHttpPromise,
  HttpError,
  HttpErrorComponent,
  IApiOptions,
  IError,
  InteractionTypeContext,
  MsalCustomContext,
  SeverityLevel,
  TokenService,
} from "consent-ui-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Customer, CustomerApi } from "customer-api-client";
import { AxiosRequestConfig } from "axios";
import "./App.scss";

interface IComponentState {
  isLoading: boolean;
  customer: Customer;
}

export const CustomerDetails = (props: { memberId: string }) => {
  const interactionType = useContext(InteractionTypeContext);
  const appInsightsContext = useAppInsightsContext();
  var publicClientApplication = useContext(MsalCustomContext);
  var options = useContext(ApplicationOptionsContext);
  const { errorState, setErrorState } = useContext(ErrorHandlingContext);
  const [state, setState] = useState<IComponentState>({
    isLoading: true,
    customer: undefined!,
  });

  const getDefaultAxiosRequestConfigCallback = useCallback(
    (options: IApiOptions) => {
      async function getDefaultAxiosRequestConfig(): Promise<AxiosRequestConfig> {
        var tokenService = new TokenService(
          publicClientApplication,
          interactionType
        );
        var accessToken = await tokenService.AquireToken(options);
        return {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Ocp-Apim-Subscription-Key": `${options.ApimSubscriptionKey}`,
          },
        };
      }
      return getDefaultAxiosRequestConfig();
    },
    [publicClientApplication, interactionType]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerApi = new CustomerApi(
          undefined,
          options.CustomerApiOptions.BaseAddress
        );
        const requestCustomerConfig =
          await getDefaultAxiosRequestConfigCallback(
            options.CustomerApiOptions
          );
        const customer = await handleHttpPromise(
          customerApi.vversionCustomerCustomerNumberGet(
            Number(props.memberId),
            "3",
            requestCustomerConfig
          )
        );
        setState((currentState) => {
          return {
            ...currentState,
            isLoading: false,
            customer: customer,
          };
        });
      } catch (error) {
        if (error instanceof HttpError) {
          if (error.severity === SeverityLevel.Critical) {
            appInsightsContext.trackException({
              exception: error,
              severityLevel: error.severity,
              properties: error.errorContext,
            });
          }
          setErrorState(() => error as IError);
        } else {
          throw error;
        }
      }
    };
    if (!props.memberId) {
      setErrorState({
        message: "MissingMemberId",
        displayMessage: ErrorMessageMissingMemberId,
        severity: SeverityLevel.Error,
      });
    } else {
      fetchData();
    }
  }, [
    appInsightsContext,
    getDefaultAxiosRequestConfigCallback,
    options.CustomerApiOptions,
    props.memberId,
    setErrorState,
  ]);

  return (
    <div>
      {errorState ? (
        <HttpErrorComponent />
      ) : (
        <>
          {!state.isLoading ? (
            <>
              <h1>Samtykke</h1>
              <h4>
                {`Medlem: ${
                  state.customer.firstName ? state.customer.firstName : ""
                } ${state.customer.lastName ? state.customer.lastName : ""}`}
              </h4>
              <h4>{"Medlemsnummer: " + state.customer.customerNumber}</h4>
            </>
          ) : (
            <div>Indlæser...</div>
          )}
        </>
      )}
    </div>
  );
};
