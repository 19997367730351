import { Consent } from "consent-ui-components";
import { useParams } from "react-router";
import { CustomerDetails } from "./CustomerDetails";
import './App.scss';

export const ConsentDialog = () => {
    const {memberId} = useParams();
    return (
      <>
        <CustomerDetails memberId={memberId as string}/>
        <div className="alignConsent">
          <Consent memberId={memberId as string}/>
        </div>
      </>
      )
}