import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  AuthenticationTemplate,
  AppInsightsContextProvider,
  ApplicationOptionsContextProvider,
  ErrorHandlingContextProvider,
  MsalCustomContextProvider,
} from "consent-ui-components";
import { InteractionType } from "@azure/msal-browser";

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ApplicationOptionsContextProvider>
      <AppInsightsContextProvider>
        <MsalCustomContextProvider>
          <AuthenticationTemplate interactionType={InteractionType.Redirect}>
            <ErrorHandlingContextProvider>
              <App />
            </ErrorHandlingContextProvider>
          </AuthenticationTemplate>
        </MsalCustomContextProvider>
      </AppInsightsContextProvider>
    </ApplicationOptionsContextProvider>
  </React.StrictMode>
);
